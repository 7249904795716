<template>
  <div class="member-shop-point-link-edit-info-block">
    <BaseElForm ref="formRef" label-position="top" :model="formData" :rules="formRules">
      <BaseElFormItem label="點數名稱">
        <p class="text-gray-60 text-sub">點數名稱</p>
      </BaseElFormItem>
      <BaseElFormItem label="圖片" prop="image" class="form-relative-label main">
        <UploadButton
          cyUploadBtn="service-img-upload-btn"
          cyUploadedImg="service-img-uploaded"
          :img="formData.image"
          :isAvatar="true"
          @change="loadImg"
        />
      </BaseElFormItem>
      <BaseElFormItem label="連結名稱" prop="name">
        <BaseElInput v-model="formData.name" placeholder="請輸入姓名" />
      </BaseElFormItem>
      <BaseElFormItem label="有效期限" prop="hasEffectivePeriod">
        <BaseElSwitch
          v-model="formData.hasEffectivePeriod"
          inactive-text="關閉"
          active-text="開啟"
        />
      </BaseElFormItem>
      <BaseElFormItem label="設定有效時間" :prop="formData.hasEffectivePeriod ? 'expiredDate' : 'startAt'">
        <el-date-picker
          v-show="formData.hasEffectivePeriod"
          v-model="formData.expiredDate"
          type="datetimerange"
          editable
          range-separator="至"
          start-placeholder="開始時間"
          end-placeholder="結束時間"
          format="yyyy-MM-dd HH:mm"
          :default-time="['00:00:00', '23:59:59']"
        />
        <el-date-picker
          v-show="!formData.hasEffectivePeriod"
          v-model="formData.startAt"
          editable
          type="datetime"
          placeholder="開始時間"
          format="yyyy-MM-dd HH:mm"
          :default-time="'00:00:00'"
        />
      </BaseElFormItem>
      <BaseElFormItem v-if="!productId" label="選擇點數" prop="shopPointKey">
        <BaseDataSelect
          :value.sync="formData.shopPointKey"
          :dataList="shopPointList"
          placeholder="請選擇點數"
        />
      </BaseElFormItem>
      <BaseElFormItem label="發放點數" prop="point">
        <BaseElInput v-model="formData.point" placeholder="請輸入數量" :disabled="!!productId">
          <template #suffix>點</template>
        </BaseElInput>
      </BaseElFormItem>
      <BaseElFormItem label="商品描述" prop="description">
        <quillEditor v-model="formData.description" :options="editorOption" />
      </BaseElFormItem>
    </BaseElForm>

    <ImageCropper
      v-if="modal.imageCropper"
      :image="formData.image"
      @uploaded="getImage"
      @close="modal.imageCropper = false"
    />
  </div>
</template>

<script>
import { defineComponent, reactive, computed, onMounted, nextTick, ref, watch } from 'vue'
import { useEditor } from '@/use/editor'
import UploadButton from '@/components/Button/UploadButton.vue'
import ImageCropper from '@/components/ImageCropper.vue'
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { useRoute } from 'vue-router/composables'
import FormsContext from '../context'
import { isDigitRules, noEmptyRules } from '@/validation'
import { omit, omitBy, get } from 'lodash'
import BaseDataSelect from '@/components/Select/BaseDataSelect.vue'
import { useShop } from '@/use/shop'

export default defineComponent({
  name: 'MemberShopPointLinkEditInfoBlock',
  components: {
    UploadButton,
    ImageCropper,
    quillEditor,
    BaseDataSelect,
  },
  props: {
    productData: { type: Object, default: () => ({}) },
  },
  setup (props) {
    const { editorOption } = useEditor()
    const route = useRoute()
    const { shopPointList } = useShop()
    const productId = computed(() => route.params.id)
    const modal = reactive({
      imageCropper: false,
    })

    const formRef = ref(null)
    const formData = reactive({
      name: null,
      hasEffectivePeriod: false,
      shopPointKey: null,
      point: null,
      description: null,
      startAt: null,
      endAt: null,
      image: null,
      expiredDate: null,
    })
    const formRules = computed(() => {
      const rules = {
        name: [noEmptyRules()],
        shopPointKey: [noEmptyRules()],
        point: [noEmptyRules(), isDigitRules()],
        description: [noEmptyRules()],
        expiredDate: [noEmptyRules()],
      }
      if (!formData.hasEffectivePeriod) {
        rules.startAt = [noEmptyRules()]
      }
      return rules
    })

    const compactData = computed(() => {
      const data = omitBy(omit(formData, ['image']), i => i === null)
      data.imageId = get(formData.image, 'id') || undefined
      if (formData.hasEffectivePeriod) {
        data.startAt = formData.expiredDate[0]
        data.endAt = formData.expiredDate[1]
      } else {
        data.startAt = formData.startAt
        data.endAt = undefined
      }
      return {
        ...data,
        shopPointKey: get(formData.shopPointKey, 'key'),
      }
    })

    const syncData = () => {
      const data = props.productData
      formData.hasEffectivePeriod = data.hasEffectivePeriod
      formData.image = data.Image
      formData.name = data.name
      formData.description = data.description
      if (data.hasEffectivePeriod) {
        formData.expiredDate = [data.startAt, data.endAt]
      } else {
        formData.startAt = data.startAt
      }
      formData.point = data.point
    }

    const getImage = (data) => {
      formData.image = data
      modal.imageCropper = false
    }
    const loadImg = (img) => {
      formData.image = img
      modal.imageCropper = true
    }

    watch(formData, () => {
      FormsContext.setFormData('info', { ...compactData.value })
    })

    onMounted(async () => {
      await nextTick()
      FormsContext.setFormRef('info', formRef.value)
      if (get(props.productData, 'id')) syncData()
    })

    return {
      get,
      formRef,
      formData,
      formRules,
      editorOption,
      getImage,
      loadImg,
      modal,
      productId,
      shopPointList,
    }
  },
})
</script>

<style lang="postcss" scoped>
.select-popper {
  @apply hidden;
}

::v-deep .el-alert__content{
  padding: 0 20px;
}

::v-deep .el-alert__description{
  @apply leading-[1];
}
.form-relative-label {
  @apply relative;
}
.form-relative-label.main::after {
  content: '(建議上傳尺寸1200x1200)';
  @apply absolute left-[50px] top-[2px] text-sm text-gray-80;
}
::v-deep .el-date-editor--datetimerange.el-input, .el-date-editor--datetimerange.el-input__inner,
::v-deep .el-date-editor--datetime.el-input, .el-date-editor--datetime.el-input__inner {
  @apply w-[560px];
}
</style>
